<template>
  <section>
    <administrators-main />
  </section>
</template>

<script>
export default {
  name: 'Administrators',
  components: {
    AdministratorsMain: () => import('@/components/Administrators/AdministratorsMain.vue')
  }
}
</script>

<style lang="scss" scoped>

</style>
